import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, takeWhile } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PermissionGrantedActions } from './permission-granted.actions';
import {
  Firestore,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { User_Permission, User_Permission_Roles, UserRole, UserType, userTypeToJSON } from 'src/app/shared/models';
import { getUserCollection } from 'src/app/shared/misc/getUserCollection';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class PermissionGrantedEffects {
  allPublishersLoading = false
  userIsAdmin = false;
  allAgenciesPermissions: User_Permission[] | null = null;

  loadPermissionsGranted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionGrantedActions.loadPermissionsGranted),
      mergeMap(({ userId, userType, grantedUserType }) => {
        return new Observable<Action>(subscriber => {
          console.log('loadPermissionsGranted', {
            userId,
            userType,
            grantedUserType,
          });

          if (this.authService.userInfo?.claims['dialerSAD']) {
            if (!this.allPublishersLoading) {
              this.allPublishersLoading = true;
              this.userIsAdmin = true;
              getDocs(query(collection(this.firestore, 'publishers'))).then(publishers => {
                const permissionList: User_Permission[] = []

                for (const doc of publishers.docs) {
                  const data = doc.data()

                  if (data['name']) {
                    permissionList.push({
                      active: true,
                      permissions: {
                      },
                      roles: {
                        admin: true
                      },
                      user: {
                        ...data,
                        userRole: UserRole.admin,
                        canImpersonate: true,
                        id: doc.id,
                        userType: UserType.PUBLISHER,
                      },
                      granterUserId: doc.id,
                      granterUserType: UserType.PUBLISHER,
                      grantedUserId: userId,
                      grantedUserType,
                    })
                  }
                }

                this.allAgenciesPermissions = permissionList;

                subscriber.next(
                  PermissionGrantedActions.loadPermissionsGrantedSuccess({
                    permissionsGranted: permissionList,
                  })
                );
              })

            } else if (this.allAgenciesPermissions) {
              subscriber.next(
                PermissionGrantedActions.loadPermissionsGrantedSuccess({
                  permissionsGranted: this.allAgenciesPermissions,
                })
              );
            }
          }

          let collectionName = 'users';
          if (grantedUserType) {
            const collectionNameString = getUserCollection(grantedUserType);
            if (collectionNameString) {
              collectionName = collectionNameString;
            }
          }

          let ref = query(
            collection(
              this.firestore,
              collectionName,
              userId,
              'private',
              'permissions',
              'received'
            )
          );

          if (userType) {
            ref = query(ref, where('userType', '==', userTypeToJSON(userType)));
          }

          const unsubscribe = onSnapshot(
            ref,
            snapshot => {
              if (snapshot.empty) {
                subscriber.next(
                  PermissionGrantedActions.loadPermissionsGrantedSuccess({
                    permissionsGranted: [],
                  })
                );
                return;
              }

              const permissionList = snapshot.docs.map(doc => {
                return User_Permission.fromJSON({
                  ...doc.data(),
                  userId: doc.id,
                  grantedUserId: userId,
                  grantedUserType,
                });
              });

              if (!this.userIsAdmin) {
                subscriber.next(
                  PermissionGrantedActions.loadPermissionsGrantedSuccess({
                    permissionsGranted: permissionList,
                  })
                );
              }
            },
            error => {
              console.log('error', error);
              subscriber.next(
                PermissionGrantedActions.loadPermissionsGrantedFailure({
                  error,
                })
              );
            }
          );

          // Provide a way of canceling and disposing the listener
          // return unsubscribe;
          return unsubscribe;
        }).pipe(
          catchError(error =>
            of({
              type: '[PermissionGranted API] Load permissionsGranted Error',
              error,
            })
          )
        );
      })
    );
  });

  // loadPermissionGranted$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionGrantedActions.loadPermissionGranted),
  //     switchMap(({ permissiongrantedId, consumerId }) => {
  //       return new Observable<Action>(subscriber => {
  //         const unsubscribe = onSnapshot(
  //           doc(
  //             this.firestore,
  //             'consumers',
  //             consumerId,
  //             'permissionsGranted',
  //             permissiongrantedId
  //           ),
  //           snapshot => {
  //             const permissiongranted = PermissionGranted.fromJSON({
  //               ...snapshot.data(),
  //               id: snapshot.id,
  //             });
  //             subscriber.next(
  //               PermissionGrantedActions.loadpermissionsGranteduccess({ permissiongranted })
  //             );
  //           },
  //           error => {
  //             subscriber.next(
  //               PermissionGrantedActions.loadPermissionGrantedFailure({ error })
  //             );
  //           }
  //         );
  //         return unsubscribe;
  //       });
  //     })
  //   );
  // });

  // addPermissionGranted$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionGrantedActions.addPermissionGranted),
  //     mergeMap(async ({ consumerId, permissiongranted }) => {
  //       try {
  //         const docRef = await addDoc(
  //           collection(this.firestore, 'consumers', consumerId, 'permissionsGranted'),
  //           permissiongranted
  //         );
  //         return PermissionGrantedActions.addpermissionsGranteduccess({
  //           permissiongranted: PermissionGranted.fromJSON({ ...permissiongranted, id: docRef.id }),
  //         }); // return new permissiongranted with id
  //       } catch (error) {
  //         return PermissionGrantedActions.addPermissionGrantedFailure({ error });
  //       }
  //     })
  //   );
  // });

  // removePermissionGranted$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionGrantedActions.removePermissionGranted),
  //     mergeMap(async ({ consumerId, permissiongrantedId }) => {
  //       try {
  //         await deleteDoc(
  //           doc(
  //             this.firestore,
  //             'consumers',
  //             consumerId,
  //             'permissionsGranted',
  //             permissiongrantedId
  //           )
  //         );
  //         return PermissionGrantedActions.removepermissionsGranteduccess({ permissiongrantedId }); // return removed permissiongranted's id
  //       } catch (error) {
  //         return PermissionGrantedActions.removePermissionGrantedFailure({ error });
  //       }
  //     })
  //   );
  // });

  // updatePermissionGranted$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(PermissionGrantedActions.updatePermissionGranted),
  //     mergeMap(async ({ consumerId, permissiongrantedId, permissiongranted }) => {
  //       try {
  //         await setDoc(
  //           doc(
  //             this.firestore,
  //             'consumers',
  //             consumerId,
  //             'permissionsGranted',
  //             permissiongrantedId
  //           ),
  //           deleteUndefined(permissiongranted),
  //           {
  //             merge: true,
  //           }
  //         );
  //         return PermissionGrantedActions.updatepermissionsGranteduccess({
  //           consumerId,
  //           permissiongrantedId,
  //           permissiongranted,
  //         }); // return updated permissiongranted's id and changes
  //       } catch (error) {
  //         return PermissionGrantedActions.updatePermissionGrantedFailure({ error });
  //       }
  //     })
  //   );
  // });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private firestore: Firestore
  ) { }
}
