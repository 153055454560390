import { UserType } from '../models';


export const userCollectionMap: { [key: number]: string } = {
  [UserType.AGENCY]: 'agencies',
  [UserType.AGENT]: 'agents',
  [UserType.AUTHORITY]: 'authorities',
  [UserType.CARRIER]: 'carriers',
  [UserType.CONSUMER]: 'consumers',
  [UserType.EMPLOYEE]: 'employees',
  [UserType.LEADSELLER]: 'leadsellers',
};

export const getUserCollection = (type?: UserType) => {
  if (!type) return undefined;

  const userCollection = userCollectionMap[type];
  return userCollection;
}

export const homePathMap: { [key: number]: string } = {
  [UserType.EMPLOYEE]: '/admin',
  [UserType.AGENT]: '/agent',
  [UserType.AGENCY]: '/admin',
};

export const getHomePath = (type?: UserType) => {
  if (!type) return '/';

  const homePath = homePathMap[type];
  return homePath || '/';
}