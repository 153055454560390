import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './shared/services/auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: environment.isDemo
      ? () => import('./shared/theme/theme-demo-border/theme-demo-border.component').then(m => m.ThemeDemoBorderComponent)
      : !environment.production
        ? () => import('./shared/theme/theme-dev/theme-dev.component').then(m => m.ThemeDevComponent)
        : undefined,
    children: [
      {
        path: 'sso',
        loadChildren: () =>
          import('./components/auth/sso/sso.routes').then((m) => m.ssoRoutes),
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadComponent: () => import('./shared/theme/theme-sidebar/theme-sidebar.component').then(m => m.ThemeSidebarComponent),
        children: [
          {
            path: '',
            loadComponent: () => import('./components/dashboard/dashboard.component').then(
              (m) => m.DashboardComponent,
            ),
          },
          {
            path: 'calls',
            loadChildren: () =>
              import('./components/calls/calls.routes').then((m) => m.callsRoutes),
          },
          {
            path: 'statistics',
            loadChildren: () =>
              import('./components/statistics/statistics.routes').then(
                (m) => m.statisticsRoutes,
              ),
          },
        ],
      },

    ],
  },
];