import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import pack from '../../package.json';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe, isPlatformServer } from '@angular/common';
import { CheckUpdateService } from './shared/services/update/check-update.service';
import { Loader } from '@googlemaps/js-api-loader';

@Component({
  imports: [AsyncPipe, FaIconComponent, RouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit {
  version = pack.version;

  //do not remove checkUpdateService as it then stops to update automatically
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public updateService: CheckUpdateService,
  ) { }

  ngAfterViewInit() {
    this.loadMaps();
  }


  loadMaps() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const loader = new Loader({
      apiKey: environment.googleMapsApiKey,
      version: 'weekly',
    });

    loader.importLibrary('core').then(() => {
      console.log('google maps loaded');
    });
  }

  backToEH() {
    window.location.href = environment.enrollhereBaseUrl
  }
}
