import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LeadCallActions } from './lead-call.actions';
import {
  Firestore,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerLeadCall } from 'src/app/shared/models';
import { consumer_Dialer_ResultCategoryToJSON, consumer_Dialer_ResultToJSON } from 'src/app/shared/models/classes/consumer';
import { EnrollmentCode } from 'src/app/shared/models/classes/misc';

@Injectable()
export class LeadCallEffects {


  loadLeadCallLive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadCallActions.loadLeadCallLive),
      mergeMap(({ agencyId, leadId, leadCallId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'leads', leadId, 'calls', leadCallId),
            (snapshot) => {
              const leadCall = DialerLeadCall.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                lead: {
                  id: leadId
                },
                agency: {
                  id: agencyId
                }
              });
              subscriber.next(LeadCallActions.loadLeadCallLiveSuccess({ leadCall }));
            },
            (error) => {
              subscriber.next(LeadCallActions.loadLeadCallLiveFailure({ error }));
            },
          );
          return unsubscribe
        }).pipe(
          catchError((error) =>
            of({ type: '[Lead API] Load Leads Live Error', error }),
          ),
        );
      }),
    );
  });

  // addLeadCall$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LeadCallActions.addLeadCall),
  //     mergeMap(async ({ leadcall }) => {
  //       try {
  //         const docRef = await addDoc(
  //           collection(this.firestore, 'leadcalls'),
  //           leadcall,
  //         );
  //         return LeadCallActions.addLeadCallSuccess({
  //           leadcall: DialerLeadCall.fromJSON({ ...leadcall, id: docRef.id }),
  //         }); // return new leadcall with id
  //       } catch (error) {
  //         return LeadCallActions.addLeadCallFailure({ error });
  //       }
  //     }),
  //   );
  // });

  // removeLeadCall$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LeadCallActions.removeLeadCall),
  //     mergeMap(async ({ leadcallId }) => {
  //       try {
  //         await deleteDoc(doc(this.firestore, 'leadcalls', leadcallId));
  //         return LeadCallActions.removeLeadCallSuccess({ leadcallId }); // return removed leadcall's id
  //       } catch (error) {
  //         return LeadCallActions.removeLeadCallFailure({ error });
  //       }
  //     }),
  //   );
  // });

  updateLeadCall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadCallActions.updateLeadCall),
      mergeMap(async ({ agencyId, leadId, leadCallId, leadCall }) => {
        console.log('updateLeadCallAction', leadCallId, leadCall);
        try {
          let updateDoc: any = {}

          if (leadCall.result) {
            updateDoc.result = consumer_Dialer_ResultToJSON(leadCall.result)
          }

          if (leadCall.resultCategory) {
            updateDoc.resultCategory = consumer_Dialer_ResultCategoryToJSON(leadCall.resultCategory)
          }

          if (leadCall.enrollmentCode) {
            updateDoc.enrollmentCode = EnrollmentCode.toJSON(leadCall.enrollmentCode)
          }



          if (Object.keys(updateDoc).length > 0) {
            await setDoc(
              doc(this.firestore, 'agencies', agencyId, 'leads', leadId, 'calls', leadCallId),
              updateDoc,
              {
                merge: true,
              }
            );
          }

          return LeadCallActions.updateLeadCallSuccess({
            agencyId,
            leadId,
            leadCallId,
            leadCall,
          });
        } catch (error) {
          return LeadCallActions.updateLeadCallFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private firestore: Firestore,
  ) { }
}
